import { Styles } from "../../types";

export const styles: Styles = {
  wrapper: {
    padding: "0 1rem",
  },
  field: {
    margin: "1rem 0",
  },
};
